<template>
	<div v-if="games.length > 0">
		<div
			class="entry f-list"
			data-css-gutter="lg"
			v-for="game in games"
			:key="game.uid">

			<div class="entry-thumbnail">
				<router-link
					class="entry-thumbnail"
					tag="a"
					:to="'/play/' + game.uid">
					<div class="img_cover">
						<img
							:src="$util.bindUrlCdn(game.thumbnail, 'c300')"
							:alt="`thumbnail of game named ${game.name}`">
					</div>
				</router-link>
			</div>

			<div class="entry-info">
				<div class="entry-name">
					<router-link
						tag="a"
						:to="'/play/' + game.uid">
						{{ game.name }}
					</router-link>
				</div>
				<div class="entry-category">
					<div>Category:&nbsp;</div>
					<div class="entry-genres">
						<router-link
							tag="a"
							v-for="genre in game.genre.split(',')"
							:key="genre"
							:to="`/genre/${genre}`"
							class="app-link"
							v-t="`genre.${game.genre}`">&#44;&nbsp;
						</router-link>
					</div>
				</div>
			</div>

			<div class="entry-delete f-list-right">
				<a @click="entryDelete">
					<i class="material-icons"> close </i>
				</a>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'component-games-list-history',
	inject: ['$util'],
	props: {
		games: Array
	},
	methods: {
		entryDelete () {
			console.log('delete!')
		}
	}
}
</script>

<style lang="scss" scoped>
.app-link {
	display: inline-block;
	color: $color-accent-pink !important;
	text-decoration: underline !important;
}

.entry {
	&.f-list { align-items: initial }

	.entry-thumbnail {
		a {
			line-height: 0;
			display: block;
			width: 6em;

			> * {
				line-height: initial;
			}
		}

		.img_cover {
			border-radius: $pd / 2;
			background-color: rgba(128, 128, 128, .1);
		}
	}

	.entry-info {
		> * {
			@extend %pd\:sm;

			padding-left: 0;
			padding-right: 0;

			&:not(:first-child) {
				padding-top: 0;
			}
		}

		.entry-name {
			font-weight: 600;
			color: $color-accent-pink;
		}

		.entry-category {
			> * {
				@extend %text-sub;

				&, > * {
					display: inline-block;
					vertical-align: middle;
				}
			}

			.entry-genres .app-link {
				&:not(:last-of-type):after {
					content: '\002C\00a0';
					display: inherit;
					visibility: inherit;
				}
			}
		}
	}

	.entry-delete {
		color: $color-accent-pink;

		.material-icons {
			font-size: 1.3em;
			font-weight: 700;
		}
	}

	&:not(:first-child) {
		margin-top: $pd;
	}
}
</style>
