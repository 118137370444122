<template>
	<main>
		<div class="app-heading pd:md">
			<div class="app-title" v-t="'history'"></div>
		</div>

		<section>
			<div v-if="isLoading" class="preloader"></div>

			<div v-else>
				<div class="pd:md">
					<component-games-list-history
						v-if="get_content_access_history.length > 0"
						:games="get_content_access_history"/>

					<div v-else>No history</div>
				</div>
			</div>
		</section>
	</main>
</template>

<script>
import ComponentGamesListHistory from '@/components/component-games-list-history'

export default {
	name: 'history',
	components: {
		ComponentGamesListHistory
	},
	data () {
		return {
			isLoading: true,
			payloadApi: [],
			get_content_access_history: []
		}
	},
	methods: {
		async init () {
			this.getHistoryPayload()

			const responses = await this.$store.dispatch('api/fetch', {
				method: 'POST',
				payload: this.payloadApi
			})

			if (responses.game.get_content_access_history[0][0].code === 2001) {
				this.get_content_access_history = responses.game.get_content_access_history[0][0].data
			}

			this.isLoading = false
		},
		getHistoryPayload () {
			const model = 'game'
			const payload = { get_content_access_history: [{ args: [{ limit: 30 }] }] }

			if (this.payloadApi[model]) {
				this.payloadApi[model].push(payload)
			} else {
				const payloadTemp = {}
				payloadTemp[model] = []
				payloadTemp[model].push(payload)
				this.payloadApi.push(payloadTemp)
			}
		}
	},
	activated () {
		console.log('fetches new history')
		this.init()
	}
}
</script>

<style lang="scss" scoped>
section {
	background-color: $color-background;
	color: $color-foreground;
}
</style>
